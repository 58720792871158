import React, { useContext } from "react"
import { navigate, Link } from "gatsby"
import { Auth } from "aws-amplify"
import { AuthStateContext } from "./context"
import Gravatar from "./gravatar"
import useMenu from "./use-menu"

export default function HeaderProfile() {
  const { authData, authState } = useContext(AuthStateContext)
  const [ref, open, setOpen] = useMenu()

  function toggle() {
    setOpen(previous => !previous)
  }

  if (authState !== "signedIn") {
    return (
      <Link className="header-link" to="/characters">
        Sign In
      </Link>
    )
  }

  return (
    <div ref={ref} className="relative">
      <button type="button" className="block" onClick={toggle}>
        <Gravatar
          className="rounded-full inline-block"
          email={authData.attributes.email}
          size={48}
        />
        <span className="header-link">{authData.username}</span>
      </button>

      {open && (
        <div
          className="rounded shadow-md mt-1 w-48 text-gray-500 bg-gray-900 absolute pin-t"
          style={{ zIndex: 9999 }}
        >
          <ul className="py-2">
            <li>
              <Link className="header-profile-menu-item" to="/characters">
                Characters
              </Link>
            </li>
            <li>
              <button
                className="header-profile-menu-item"
                type="button"
                onClick={() => {
                  navigate("/")
                  Auth.signOut()
                }}
              >
                Sign Out
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
