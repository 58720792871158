import React from "react"
import ErrorPanel from "./error-panel"

export default class ErrorBoundary extends React.Component {
  static defaultProps = {
    FallbackComponent: ErrorPanel,
  }

  state = {
    error: null,
    info: null,
  }

  componentDidCatch(error, info) {
    this.setState({ error, info })
  }

  render() {
    const { children, FallbackComponent } = this.props
    const { error, info } = this.state

    if (error !== null) {
      return (
        <FallbackComponent
          componentStack={info ? info.componentStack : ""}
          error={error}
          onDismiss={() => this.setState({ error: null, info: null })}
        />
      )
    }

    return children || null
  }
}
