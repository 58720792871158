import { useRef, useState, useEffect } from "react"

export default function useMenu() {
  const ref = useRef()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    function closeMenu(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }

    document.addEventListener("mouseup", closeMenu)

    return () => {
      document.removeEventListener("mouseup", closeMenu)
    }
  }, [])

  return [ref, open, setOpen]
}
