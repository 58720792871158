import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import BackgroundImage from "gatsby-background-image"
import { Authenticator } from "aws-amplify-react"
import { AuthStateContext } from "./context"
import ErrorBoundary from "./error-boundary"
import Header from "./header"
import "./layout.css"

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export function Layout({
  background,
  backgroundColor,
  children,
  authData,
  authState,
  onStateChange,
  className,
  style,
}) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let content = (
    <>
      <Header title={data.site.siteMetadata.title} />
      <div className="px-4 pb-6 w-screen">
        <main className={className} style={style}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </main>
      </div>
    </>
  )

  if (background) {
    content = (
      <BackgroundImage fluid={background} backgroundColor={backgroundColor}>
        {content}
      </BackgroundImage>
    )
  } else if (backgroundColor) {
    content = <div style={{ backgroundColor }}>{content}</div>
  }

  return (
    <AuthStateContext.Provider value={{ authData, authState, onStateChange }}>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.8.2/css/brands.css"
          integrity="sha384-i2PyM6FMpVnxjRPi0KW/xIS7hkeSznkllv+Hx/MtYDaHA5VcF0yL3KVlvzp8bWjQ"
          crossorigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.8.2/css/solid.css"
          integrity="sha384-ioUrHig76ITq4aEJ67dHzTvqjsAP/7IzgwE7lgJcg2r7BRNGYSK0LwSmROzYtgzs"
          crossorigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.8.2/css/fontawesome.css"
          integrity="sha384-sri+NftO+0hcisDKgr287Y/1LVnInHJ1l+XC7+FOabmTTIK0HnE2ID+xxvJ21c5J"
          crossorigin="anonymous"
        />
      </Helmet>

      {content}
    </AuthStateContext.Provider>
  )
}

export default props => (
  <Authenticator hideDefault>
    <Layout {...props} />
  </Authenticator>
)
