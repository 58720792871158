import React from "react"
import "./post.css"

export default function Post({ frontmatter, timeToRead, html }) {
  return (
    <>
      <h2 className="post-subtitle">
        <span className="mr-5">{frontmatter.date}</span>
        <i className="fas fa-clock" /> {timeToRead} min
      </h2>
      <h1 className="post-title">{frontmatter.title}</h1>
      <div className="post" dangerouslySetInnerHTML={{ __html: html }} />
    </>
  )
}
