import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { Auth } from "aws-amplify"
import SiteIcon from "./site-icon"
import HeaderProfile from "./header-profile"
import { AuthStateContext } from "./context"
import useMenu from "./use-menu"

Header.propTypes = {
  title: PropTypes.string.isRequired,
}

export default function Header({ title }) {
  const [ref, open, setOpen] = useMenu()
  const { authState } = useContext(AuthStateContext)

  return (
    <div ref={ref}>
      <header className="header clearfix">
        <div className="xl:mx-auto xl:max-w-6xl">
          <div className="float-left h-20 flex pl-6 py-4">
            <SiteIcon className="flex-initial mr-4" />

            <Link className="header-title" to="/">
              {title}
            </Link>

            <div className="hidden md:block pt-3">
              <Link className="header-link" to="/about">
                About
              </Link>

              <Link className="header-link" to="/posts">
                Updates
              </Link>

              <Link className="header-link" to="/media">
                Media
              </Link>

              <Link className="header-link" to="/servers">
                Servers
              </Link>
            </div>
          </div>

          <div className="flex float-right h-20 flex-row-reverse items-center pr-6 py-4">
            <div className="hidden lg:block">
              <HeaderProfile />
            </div>

            <div className="block lg:hidden">
              <button
                type="button"
                onClick={() => setOpen(previous => !previous)}
                className="header-link"
              >
                <i className="fas fa-bars" />
              </button>
            </div>
          </div>
        </div>
      </header>
      {open && (
        <ul className="bg-gray-900">
          {authState === "signedIn" ? (
            <>
              <li>
                <button
                  type="button"
                  className="header-link font-bold"
                  onClick={() => {
                    navigate("/")
                    Auth.signOut()
                  }}
                >
                  Sign Out
                </button>
              </li>
              <li>
                <Link className="header-link" to="/characters">
                  Characters
                </Link>
              </li>
            </>
          ) : (
            <li>
              <Link className="header-link" to="/characters">
                Sign In
              </Link>
            </li>
          )}
          <li>
            <Link className="header-link" to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className="header-link" to="/posts">
              Updates
            </Link>
          </li>
          <li>
            <Link className="header-link" to="/media">
              Media
            </Link>
          </li>
          <li>
            <Link className="header-link" to="/servers">
              Servers
            </Link>
          </li>
        </ul>
      )}
    </div>
  )
}
