import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"

PostPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default function PostPage({ data, pageContext }) {
  const { title, description } = data.post.frontmatter
  const { next, previous } = pageContext

  const links = (
    <div className="text-salmon clearfix">
      {previous && (
        <div className="float-left">
          <span className="opacity-75">Previous</span>{" "}
          <Link to={previous.frontmatter.path}>
            {previous.frontmatter.title}
          </Link>
        </div>
      )}
      {next && (
        <div className="float-right">
          <span className="opacity-75">Next</span>{" "}
          <Link to={next.frontmatter.path}>{next.frontmatter.title}</Link>
        </div>
      )}
    </div>
  )

  return (
    <Layout>
      <SEO title={title} description={description} />
      <div className="m-auto w-4/5">
        {links}

        <Post {...data.post} />

        {links}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
