import React, { useEffect, useState } from "react"

export default function ErrorPanel({ componentStack, error, onDismiss }) {
  const [hidden, hide] = useState(false)
  useEffect(() => {
    hide(false)
  }, [error])

  if (hidden || !error) return null

  return (
    <div className="error-panel">
      <code>{error.toString()}</code>

      {componentStack != null && (
        <>
          <p>This is located at:</p>
          <pre>{componentStack}</pre>
        </>
      )}

      {(typeof onDismiss === "function" && (
        <div className="mt-4">
          <button
            className="error-panel-button"
            type="button"
            onClick={() => onDismiss()}
          >
            Retry
          </button>
        </div>
      )) || (
        <div className="mt-4">
          <button
            className="error-panel-button"
            type="button"
            onClick={() => hide(true)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  )
}
