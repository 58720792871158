import React from "react"
import PropTypes from "prop-types"
import md5 from "md5"

Gravatar.propTypes = {
  email: PropTypes.string,
  size: PropTypes.number,
}

export default function Gravatar({ email = "", size = 50, ...rest }) {
  const gravatar = `https://www.gravatar.com/avatar/${md5(
    email
  )}?d=mm&size=${size}`
  return (
    <img
      src={gravatar}
      alt="Profile Avatar"
      style={{ width: size, height: size }}
      {...rest}
    />
  )
}
